import React, { useState } from 'react'
import styled from 'styled-components'
import theme from '../styling/theme'
import MainGrid from '../styling/MainGrid'
import Button from '../shared/Button/Button'
import SectionHeading from '../shared/SectionHeading'
import VerticalPadding from '../styling/VerticalPadding'
import MakeClaimModal from '../shared/Modal/MakeClaimModal'
import RequestCallBackModal from '../shared/Modal/RequestCallBackModal'

const GreenCards = () => {
  const [showCallBackModal, toggleCallBackModal] = useState(false)
  const [showClaimModal, toggleClaimModal] = useState(false)
  return (
    <Container>
      <MakeClaimModal
        showModal={showClaimModal}
        toggleModal={() => toggleClaimModal(false)}
        type={'Get in touch'}
        body={'Inactive account'}
      />
      <RequestCallBackModal
        showModal={showCallBackModal}
        toggleModal={() => toggleCallBackModal(false)}
        type={'Request a call back'}
        body={'Inactive account'}
      />
      <VerticalPadding>
        <MainGrid noPaddingMobile>
          <HeadingContainer>
            <p className="section-tag">WHY IT PAYS TO BE WITH RDA</p>
            <SectionHeading center>What makes us different</SectionHeading>
          </HeadingContainer>
          <Grid>
            <CardGreen>
              <CardHeading white>
                <b>With </b>RDA
              </CardHeading>
              <Section white>
                <h6>We keep you up to date with government legislation</h6>
                <p className="p-large">
                  With our plain-English bulletins, we run through all the
                  latest initiatives for R&D, so you don’t have to. If you want
                  it, we will also provide R&D best-practice coaching and
                  workshops.
                </p>
              </Section>
              <Section white>
                <h6>Personalised one-to-one approach</h6>
                <p className="p-large">
                  Our dedicated Client Managers will work with you to provide an
                  individual, forensic analysis on each project you undertake.
                  We provide a confidential R&D record-keeping service and
                  intellectual-property assessment and advocacy you can call on
                  at any time.
                </p>
              </Section>
              <Section white>
                <h6>We’ll do all the hard work for you</h6>
                <p className="p-large">
                  We also understand that businesses are busy, that time is a
                  valuable asset and our process is designed to take up as
                  little of it as possible. Even so, we are there whenever you
                  need us.
                </p>
              </Section>
            </CardGreen>
            <Card>
              <CardHeading>
                <b>Without </b>RDA
              </CardHeading>
              <Section>
                <h6>No personalised one-to-one contact</h6>
                <p className="p-large">
                  Breaking new ground with R&D is not a straightforward process
                  and how a project is communicated to HMRC is the key to a
                  successful claim. The task requires not only an expert tax
                  agent, but also an expert communicator.
                </p>
              </Section>
              <Section>
                <h6>Extensive form fill</h6>
                <p className="p-large">
                  Claims under the HMRC Tax Credits Scheme can be complex and
                  laborious to complete, requiring a lot of thresholds to be
                  met. As the company behind the R&D, jumping through all those
                  hoops alone only makes it harder.
                </p>
              </Section>
              <Section>
                <h6>No best-practice advice</h6>
                <p className="p-large">
                  To understand how best to undertake R&D work, with the methods
                  and processes involved, requires a breadth of experience in
                  dealing with lots of different companies in lots of different
                  sectors. Not every R&D firm or tax agent can say they do.
                </p>
              </Section>
            </Card>
          </Grid>
          <ButtonContainer>
            <Button onClick={() => toggleClaimModal(true)}>Make a claim</Button>
            <Button onClick={() => toggleCallBackModal(true)} secondary>
              Request a call back
            </Button>
          </ButtonContainer>
        </MainGrid>
      </VerticalPadding>
    </Container>
  )
}

export default GreenCards

const Container = styled.section``

const HeadingContainer = styled.div`
  width: 100%;
  max-width: 918px;
  margin: 0 auto;
  text-align: center;
  padding: 0 20px;
  @media (min-width: 768px) {
    padding: 0;
  }
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  margin-top: 30px;
  @media (min-width: 768px) {
    margin-top: 54px;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 3rem;
  }
`
const Card = styled.div`
  background: ${theme.background.hpHeader};
  padding: 47px 20px 40px;

  @media (min-width: 768px) {
    border-radius: 4px;
  }
  @media (min-width: 1024px) {
    padding: 80px 58px;
  }
`
const CardGreen = styled(Card)`
  background: ${theme.brand.blue};
  color: white;
`
interface ICardHeading {
  white?: boolean
}
const CardHeading = styled.h3<ICardHeading>`
  color: ${props => props.white && 'white'};
  font-weight: 300;
  font-family: Roboto;
  padding-bottom: 32px;
  text-align: center;
  border-bottom: 1px solid
    ${props => (props.white ? theme.grey.white80 : theme.grey.keyline)};

  @media (min-width: 768px) {
    text-align: left;
    padding-bottom: 47px;
  }
`

const Section = styled.div<ICardHeading>`
  padding: 35px 0;
  border-bottom: 1px solid
    ${props => (props.white ? theme.grey.white80 : theme.grey.keyline)};

  & p {
    color: ${props =>
      props.white ? 'rgba(255,255,255,0.8)' : theme.text.greyLight};
  }
  &:last-of-type {
    border-bottom: none;
    padding: 35px 0 0;
  }

  & h6 {
    font-weight: 700;
    margin-bottom: 8px;
    color: ${props => (props.white ? theme.text.white : theme.text.main)};
  }

  & .p-large {
  }
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  width: 100%;
  padding: 0 20px;
  align-items: center;
  justify-content: center;

  & a:first-of-type {
    margin-bottom: 11px;
  }
  @media (min-width: 768px) {
    margin-top: 32px;
    max-width: 470px;
    margin: 40px auto 0;
    padding: 0;
    flex-direction: row;
    & a:first-of-type {
      margin-right: 10px;
      margin-bottom: 0;
    }
  }
`
