import React from 'react'
import Layout from '../components/Layout'
import CallBackBanner from '../components/CallBackBanner'
import CarouselDrag from '../components/CarouselDrag'

import TrackVisibility from 'react-on-screen'
import HomepageHeader from '../components/Header/HomepageHeader'
import ThreeCards from '../components/ThreeCards/ThreeCards'
import GreenCards from '../components/GreenCards/GreenCards'
import IconsWithText from '../components/IconsWithText/IconsWithText'
import PromotionBanner from '../components/PromotionBanner/PromotionBanner'
import WhatIsResearch from '../components/WhatIsResearch/WhatIsResearch'
import PopInAnimation from '../components/PopInAnimation/PopInAnimation'
import CarouselImage from '../components/CarouselImage/CarouselImage'
import CarouselImage2 from '../components/CarouselImage/CarouselImage2'
import MeetTheTeamHomepage from '../components/MeetTheTeamHomepage/MeetTheTeamHomepage'

import icon1 from '../images/homepage/what-is-research/money.svg'
import icon2 from '../images/homepage/what-is-research/laptop.svg'
import icon3 from '../images/homepage/what-is-research/green-circle.svg'

const whatIsResearchcontent = [
  {
    icon: icon1,
    iconAlt: 'Invest money in research and development',
    body: 'Invest money in research and development',
  },
  {
    icon: icon2,
    iconAlt: 'Make a claim',
    body: 'Make a claim',
  },
  {
    icon: icon3,
    iconAlt: 'Reclaim up to 33%',
    body: 'HMRC pays up to 33% of your R&D costs',
    lightText: 'Reclaim up to',
  },
]

export default class IndexPage extends React.Component {
  render() {
    return (
      <Layout
        title="RDA"
        description="RDA - At RDA we bring a unique, consultancy approach to your Research & Development tax claims."
      >
        <HomepageHeader />

        {/* <PostList posts={posts} title="Latest posts" /> */}
        {/* <Pagination pageContext={pageContext} pathPrefix="/" /> */}

        <TrackVisibility partialVisibility>
          <WhatIsResearch
            content={whatIsResearchcontent}
            animation
            tag="what is  research and development?"
            heading="R&D Tax Credit Claims Made Simple!"
            body={[
              'Research & Development (R&D) tax relief is the government’s incentive to reward the time and investment UK businesses have put into solving unusual business problems. It allows firms to claim additional tax savings on many of the costs they incur while developing new products or processes, or improving existing ones.',
            ]}
          />
        </TrackVisibility>
        <TrackVisibility offset={600}>
          <PopInAnimation />
        </TrackVisibility>
        <ThreeCards
          topHeading="HOW DO YOU QUALIFY FOR R&D?"
          heading="Is your business eligible for R&D tax credit?"
          cardsContent={[
            {
              title: 'Limited Company',
              body:
                'All UK-registered limited companies are eligible under the R&D Tax Credit Scheme. A limited liability partnership (LLP) may only claim if the R&D project is undertaken in partnership with a Corporation Tax-paying firm. Sole traders are ineligible.',
            },
            {
              title: 'Carrying out R&D activities',
              body:
                'HMRC set down a complex set of criteria as to what kind of activity qualifies for relief under the R&D Tax Credit Scheme. Fundamentally, the R&D must involve the increase in overall knowledge or capability in a field of science or technology. This means improving on the knowledge that’s publicly available or the capability of a competent professional working in the field.',
            },
            {
              title: 'How your spending qualifies',
              body:
                'There is a lot that can qualify for R&D tax relief. This can range from planning, designing, testing and analysis methods through to the creation and adaptation of materials, equipment or software. Even staff time given to things like the record-keeping, maintenance, security protection and training in relation to the R&D project can all count under the R&D Tax Credit Scheme.',
            },
          ]}
        />

        <PromotionBanner />
        <IconsWithText />
        <GreenCards />
        <CarouselImage2 id="homepage-car-1" />
        <MeetTheTeamHomepage />
        <CarouselDrag id="carousel-homepage-drag" />

        <CallBackBanner />
      </Layout>
    )
  }
}
