import React from 'react'
import styled from 'styled-components'
import Button from '../shared/Button/Button'
import MainGrid from '../styling/MainGrid'
import img1 from '../../images/homepage/desk.svg'
import bottom from '../../images/homepage/bottom.svg'
import lines from '../../images/homepage/header-lines.svg'
import border from '../../images/homepage/border.svg'

const dataContent = {
  btnTitle1: 'Find out how',
}

const HomepageHeader = () => {
  return (
    <Container>
      {dataContent && (
        <MainGrid>
          <Inner>
            <TextWrapper>
              <MainHeading>
                Develop <i>Together</i> : It’s the RDA Way
              </MainHeading>

              <SubHeading>
                At RDA we bring a unique, consultancy approach to your
                Research & Development tax claims.
              </SubHeading>

              <ButtonContainer>
                <Button
                  href={
                    (process.env.BASE === '/' ? '' : process.env.BASE) +
                    '/tax-credit'
                  }
                >
                  {dataContent.btnTitle1}
                </Button>
              </ButtonContainer>
            </TextWrapper>
            <ImgMask>
              <Img src={img1} alt="Woman and man analyzing graph." />
              <Bottom src={bottom} alt="Floor" />
            </ImgMask>
          </Inner>
        </MainGrid>
      )}
      <BorderBottom src={border} alt="Floor" />
    </Container>
  )
}

export default HomepageHeader
const Container = styled.header`
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 17px 0 0px;
  min-height: 260px;
  background: url(${lines}),
    linear-gradient(to bottom, #e4efe1 1%, #eaefdd, #f2efd8);

  width: 100%;
  position: relative;
  margin-bottom: 40px;
  @media screen and (min-width: 768px) {
    padding: 9rem 0 0;
    flex-direction: row;
  }
`

const Inner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    position: relative;
  }
`
const TextWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 555px;
  position: relative;
  z-index: 8;
  text-align: center;
  margin-bottom: 22px;
  @media (min-width: 768px) {
    position: relative;
    padding-bottom: 14rem;
    text-align: left;
    align-items: flex-start;
    margin-bottom: 0;
    width: 50%;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 420px;
  margin-top: 15px;

  @media (min-width: 768px) {
    flex-direction: row;
    margin-top: 24px;
    max-width: 100%;
    align-items: flex-start;
    max-width: 280px;
  }

  @media (min-width: 1024px) {
    max-width: 300px;
  }
`
const SubHeading = styled.h6`
  margin-top: 15px;
  font-weight: 300;

  & b {
    font-weight: 700;
  }
  @media (min-width: 768px) {
    width: 80%;
  }
`

const MainHeading = styled.h1``

const ImgMask = styled.div`
  width: 70vw;
  @media (min-width: 768px) {
    width: 50vw;
    position: relative;
    right: -3%;
    max-width: 62rem;
  }
`

const Img = styled.img`
  width: 100%;
`
const BorderBottom = styled.img`
  width: 100%;
  position: absolute;
  bottom: 0;
`
const Bottom = styled.img`
  top: 100%;

  position: absolute;
  right: 0;
  width: 100%;
  max-width: 80rem;
  @media (min-width: 768px) {
    top: 99%;
  }
`
