import React, { useState } from 'react'
import styled from 'styled-components'
import theme from '../styling/theme'
import arrowGreen from '../../images/arrCarousel.svg'
import arrowGrey from '../../images/arrGrey.svg'
import InlineLink from '../shared/InlineLink'
import img1 from '../../images/homepage/hp_sponsorship_motorsport.jpg'
import img2 from '../../images/homepage/hp_sponsors_lordTavernes.jpg'
import img3 from '../../images/homepage/hp_sponsorship_wildlife.jpg'
import img4 from '../../images/homepage/hp_sponsorships_cricket.jpg'

interface CarouselProps {
  id: string
}

const data = [
  {
    img: img1,
    imgMob: img1,
    alt: 'OUR SPONSORSHIP',
  },
  {
    img: img2,
    imgMob: img2,
    alt: 'OUR SPONSORSHIP',
  },
  {
    img: img3,
    imgMob: img1,
    alt: 'OUR SPONSORSHIP',
  },
  {
    img: img4,
    imgMob: img1,
    alt: 'OUR SPONSORSHIP',
  },
]

const content = {
  tag: 'OUR SPONSORSHIP',
  heading:
    'We’re committed to creating opportunities in the sport and sustainability sectors through our sponsorship partners.',
  body:
    'At RDA we love sport – and we find the commitment and discipline of the sports teams and athletes we work with inspirational. That’s why we try to give something back to the sports sector through our sponsorships. We also care deeply about sustainability, which is why we became platinum partners of the Essex Wildlife Trust. Discover more about our motivations.',
}

const imgMob = img1
const alt = 'OUR SPONSORSHIP'

const CarouselImage = ({ id }: CarouselProps) => {
  let moveGreenScrollBarValue = 100 / data.length

  const [clientScroll, setClientScroll] = useState(0)

  const [activeSlide, changeSlide] = useState(0)

  const [scrollBarValue, moveScrollbar] = useState(0)

  const nextSlide = () => {
    if (activeSlide < data.length - 1) {
      changeSlide(activeSlide + 1)
      if (typeof document !== 'undefined') {
        const greenScrollBar = document.getElementById(
          'green-line-img-carousel'
        )
        let newScrollValue = scrollBarValue + moveGreenScrollBarValue
        moveScrollbar(newScrollValue)
        greenScrollBar.style.left = newScrollValue + '%'
        // console.log(scrollBarValue)
      }
    }
  }

  const prevSlide = () => {
    if (activeSlide > 0) {
      changeSlide(activeSlide - 1)
      if (typeof document !== 'undefined') {
        const greenScrollBar = document.getElementById(
          'green-line-img-carousel'
        )
        let newScrollValue = scrollBarValue - moveGreenScrollBarValue
        greenScrollBar.style.left = newScrollValue + '%'
        moveScrollbar(newScrollValue)
        // console.log(scrollBarValue)
      }
    }
  }

  const setSlide = i => {
    changeSlide(i)
  }

  const setClientX = e => {
    const clientX = e.touches[0].clientX
    setClientScroll(clientX)
  }

  const handleTouch = e => {
    if (e) {
      const touchStart = e.changedTouches[0].clientX
      const touchEnd = clientScroll

      //get end touch coordinates
      if (touchStart > touchEnd) {
        prevSlide(e)
      } else {
        nextSlide(e)
      }
    }
  }
  return (
    <Outer>
      <SlideContainer id={id}>
        <Text>
          {content.tag && <Tag className="section-tag">{content.tag}</Tag>}
          <SlideTitle>{content.heading}</SlideTitle>
          <BodyText className="p-large">{content.body}</BodyText>
          <InlineLinkWrapper>
            <InlineLink
              text="Learn more"
              icon
              to={
                (process.env.BASE === '/' ? '' : process.env.BASE) +
                '/sponsorships'
              }
            />
          </InlineLinkWrapper>
          <ProgressContainer>
            <ArrowsContainer>
              <ChevronLeft
                src={activeSlide !== 0 ? arrowGreen : arrowGrey}
                first={activeSlide === 0}
                onClick={prevSlide}
                alt="chevron"
              />
              <ChevronRight
                src={activeSlide !== data.length - 1 ? arrowGreen : arrowGrey}
                last={activeSlide === data.length - 1}
                onClick={nextSlide}
                alt="chevron"
              />
            </ArrowsContainer>
            <Scrollbar>
              <GreenProgress
                width={`${100 / data.length}%`}
                id="green-line-img-carousel"
              />
            </Scrollbar>
          </ProgressContainer>
        </Text>
        {imgMob && <ImageMob src={imgMob} alt={alt} />}
        <SlideWrapper>
          <SlideAnimator activeSlide={activeSlide}>
            {data.map((slide, i) => {
              return (
                <SlideMask key={i}>
                  <Slide
                    activeSlide={activeSlide === i}
                    slideDirection={i > activeSlide ? -50 : -50}
                  >
                    {slide.img && (
                      <Image
                        src={slide.img}
                        alt={slide.alt}
                        activeSlide={i === activeSlide}
                        onTouchStart={setClientX}
                        onTouchEnd={handleTouch}
                      />
                    )}
                  </Slide>
                </SlideMask>
              )
            })}
          </SlideAnimator>
        </SlideWrapper>
      </SlideContainer>
    </Outer>
  )
}

interface ISlideAnimator {
  carouselWidth: number
  activeSlide: number
  tabletCarouselWidth: number
}
const Outer = styled.div`
  width: 100%;
  margin: 0 auto;
  position: relative;
  margin-bottom: -100px;
  @media (min-width: 768px) {
    margin-bottom: 0;
    padding: 0 20px;
  }
  @media (min-width: 1024px) {
    max-width: 1140px;
    padding: 0 3%;
  }
  @media (min-width: 1200px) {
    width: 90%;
    padding: 5rem 0;
  }
`
const SlideWrapper = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  @media (min-width: 768px) {
    margin-left: auto;
    width: 60%;
    height: 75rem;
  }
`

const SlideAnimator = styled.div<ISlideAnimator>`
  display: flex;
  transition: all 0.5s ease;
  width: 100%;
  backface-visibility: hidden;
  transform: translateX(-${props => 100 * props.activeSlide}%);
  transition: transform 2s ease;

  @media (min-width: 768px) {
    margin-left: auto;
    height: 100%;
  }
`
interface ISlideContainer {
  activeSlide: number
}
const SlideContainer = styled.div<ISlideContainer>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column-reverse;
  position: relative;
  backface-visibility: hidden;
  scroll-snap-type: x mandatory;
  transform-style: preserve-3d;
  perspective: 1000px;
  will-change: transform;
  margin-left: auto;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`
interface SlideProps {
  activeSlide?: boolean
  slideDirection: number
}
const Slide = styled.div<SlideProps>`
  display: flex;
  width: 100%;
  height: 100%;
  padding-top: 25px;
  flex-shrink: 0;
  scroll-snap-align: start;
  transform: perspective(1000px);
  background: white;
  position: relative;
  perspective: 1000px;
  overflow: hidden;
  will-change: transform;
  transition: transform 2s ease;
  z-index: ${props => (props.activeSlide ? '2' : '1')};

  @media (max-width: 1023px) {
    flex-direction: column;
    justify-content: flex-end;
  }

  @media (min-width: 1024px) {
    padding-top: 0;
    margin-left: auto;
    height: 100%;
  }
`
const SlideMask = styled.div`
  width: 100%;
  flex-shrink: 0;
  overflow: hidden;
  background: white;
  @media (max-width: 768px) {
    position: relative;
    margin-bottom: -100px;
  }
`

const SlideTitle = styled.h4`
  margin-bottom: 11px;
  font-weight: bold;
  @media (min-width: 1024px) {
    margin-bottom: 14px;
  }
`

const BodyText = styled.p``

const Text = styled.div`
  width: calc(100% - 40px);
  margin: 0 20px;
  padding: 58px 28px 43px;
  position: relative;
  backface-visibility: hidden;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 -4px 20px 5px rgba(100, 100, 100, 0.1);
  z-index: 10;
  top: -100px;
  @media (min-width: 768px) {
    box-shadow: 0 -4px 40px 11px rgba(100, 100, 100, 0.1);
    margin: 0;
    width: 50rem;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  @media (min-width: 1024px) {
    width: 60rem;
    padding: 7.2rem 6.8rem 6rem;
  }
`

const Tag = styled.p`
  margin-bottom: 10px;
  @media (min-width: 1024px) {
    margin-bottom: 24px;
  }
`
const Mask = styled.div`
  overflow: hidden;
  width: 100%;
  perspective: 1000px;
  height: 500px;
  min-height: 500px;
  position: relative;
  z-index: -10;
  @media (min-width: 1024px) {
    top: 0;
    right: 0;
    top: 0;
    height: 100%;
    min-height: 100%;
    width: 100%;
  }
`
const Image = styled.img<SlideProps>`
  width: 100%;
  height: 500px;
  min-height: 500px;
  object-fit: cover;
  margin: auto 0;
  display: block;
  transform-origin: top center;
  will-change: transform;
  display: none;
  @media (min-width: 768px) {
    height: 100%;
    display: block;
  }
`
const ImageMob = styled.img`
  display: block;
  width: 100%;
  height: 500px;
  min-height: 500px;
  object-fit: cover;
  margin: auto 0;
  display: block;
  transform-origin: top center;
  will-change: transform;
  @media (min-width: 768px) {
    display: none;
  }
`

const ProgressContainer = styled.div`
  display: none;

  @media (min-width: 768px) {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`

const ArrowsContainer = styled.div`
  width: 90px;
  display: flex;
  justify-content: space-between;

  @media (min-width: 1024px) {
    width: 134px;
  }
`

interface IChevron {
  first?: boolean
  last?: boolean
}
const ChevronRight = styled.img<IChevron>`
  width: 33px;
  cursor: pointer;
  z-index: 10;
  margin-left: 20px;
  @media (min-width: 1024px) {
    width: 57px;
  }
`

const ChevronLeft = styled(ChevronRight)`
  transform: rotate(180deg);
  margin-left: 0;
`
const InlineLinkWrapper = styled.div`
  margin-top: 10px;
  display: flex;

  @media (min-width: 768px) {
    margin-bottom: 50px;
  }
  @media (min-width: 1024px) {
    margin-top: 16px;
    margin-bottom: 6rem; /* to fit the content */
  }
`

const Scrollbar = styled.div`
  background: ${theme.grey.scroll};
  width: 100%;
  height: 1px;
  margin-left: 16px;
`

interface GreenProgressProps {
  width: string
}
const GreenProgress = styled.div<GreenProgressProps>`
  background: ${theme.brand.blue};
  height: 3px;
  width: ${props => props.width};
  border-radius: 8px;
  position: relative;
  top: -1px;
`
export default CarouselImage
