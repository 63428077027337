import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import MainGrid from '../styling/MainGrid'
import theme from '../styling/theme'
import img1 from '../../images/homepage/eligible.png'
import SectionHeading from '../shared/SectionHeading'

interface Item {
  title: string
  body: string
}

interface ThreeCardsProps {
  heading: string
  topHeading?: string
  cardsContent: Array<Item>
}
const ThreeCards: FunctionComponent<ThreeCardsProps> = ({
  heading,
  topHeading,
  cardsContent,
}) => {
  return (
    <Container>
      <MainGrid>
        <HeadingContainer>
          {topHeading && <p className="section-tag">{topHeading}</p>}
          {heading && <SectionHeading center>{heading}</SectionHeading>}
        </HeadingContainer>
        <Inner>
          <Mask>
            <Img src={img1} alt="Team meeting at a table" />
          </Mask>

          <CardsWrapper id={'courses-container'}>
            {cardsContent &&
              cardsContent.map((card, key) => {
                return (
                  <Card key={key}>
                    {card.title && <CardTitle>{card.title}</CardTitle>}
                    {card.body && <CardBody>{card.body}</CardBody>}
                  </Card>
                )
              })}
          </CardsWrapper>
        </Inner>
      </MainGrid>
    </Container>
  )
}

export default ThreeCards

const Container = styled.section`
  padding: 30px 0 60px;
  @media (min-width: 768px) {
    padding: 30px 0 90px;
  }
`
const HeadingContainer = styled.div`
  width: 100%;
  max-width: 650px;
  margin: 0 auto;
  text-align: center;
`
const Inner = styled.div`
  display: flex;
  flex-direction: column-reverse;
  margin-top: 18px;
  @media (min-width: 768px) {
    flex-direction: column;
    /* align-items: center; */
    justify-content: space-between;
    margin-top: 65px;
  }
  @media (min-width: 1024px) {
    flex-direction: row;
    /* align-items: center; */
    justify-content: space-between;
    margin-top: 65px;
  }
`

const Mask = styled.div`
  width: 100%;
  margin: 20px auto 0;
  position: relative;
  @media (min-width: 768px) {
    margin: 0 auto;
    max-width: 50%;
  }
  @media (min-width: 1024px) {
    margin: 0 6rem 0 0;
  }
`

const Img = styled.img`
  width: 100%;
`

const CardsWrapper = styled.div`
  width: 100%;

  @media (min-width: 768px) {
    width: 100%;
    display: flex;
    margin-top: 20px;
  }
  @media (min-width: 1024px) {
    display: block;
    width: 44%;
    margin-top: 0px;
  }
`

const Card = styled.div`
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
  background: white;
  padding: 28px 25px 27px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  border: 1px solid ${theme.grey.keylineDark};

  @media (min-width: 768px) {
    padding: 30px 25px 24px;
    margin-bottom: 23px;
    flex: 1;
    &:not(:last-of-type) {
      margin-right: 10px;
    }
  }
  @media (min-width: 1024px) {
    padding: 30px 60px 24px 48px;
    margin-bottom: 23px;
  }
`

const CardTitle = styled.h6`
  font-weight: 700;
  margin-bottom: 5px;
  @media (min-width: 1024px) {
  }
`

const CardBody = styled.p``
