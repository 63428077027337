import React from 'react'
import styled from 'styled-components'
import SectionHeading from '../shared/SectionHeading'
import VerticalPadding from '../styling/VerticalPadding'
import icon1 from '../../images/homepage/icons-with-text/10Billion.svg'
import icon2 from '../../images/homepage/icons-with-text/no_fees.svg'
import icon3 from '../../images/homepage/icons-with-text/100_satisfaction.svg'
import icon4 from '../../images/homepage/icons-with-text/hmrc.svg'

import MainGrid from '../styling/MainGrid'

const data = [
  {
    icon: icon1,
    iconAlt: ' £10 Billion HMRC tax relief tax agents',
    body:
      'Over £10 Billion of tax relief support given to UK companies since 2000',
  },
  {
    icon: icon2,
    iconAlt: 'No upfront fees',
    body:
      'What we earn from working with you is directly linked to how we perform',
  },
  {
    icon: icon3,
    iconAlt: '100% Satisfaction rate',
    body:
      'Every single one of our clients reports 100% satisfaction with our work',
  },
  {
    icon: icon4,
    iconAlt: 'Trusted HMRC partner',
    body:
      'We are registered tax agents, authorised to submit your R&D claim directly to HMRC',
  },
]
const IconsWithText = () => {
  return (
    <Container>
      <VerticalPadding>
        <MainGrid>
          <HeadingContainer>
            <p className="section-tag">GREAT REASONS TO CHOOSE US</p>
            <SectionHeading center>
              Why is RDA the company to take care of your claim?
            </SectionHeading>
          </HeadingContainer>
          <Grid>
            {data &&
              data.map((item, key) => {
                return (
                  <Card key={key}>
                    <Icon src={item.icon} alt={item.iconAlt} />
                    <Body className="p-large">{item.body}</Body>
                  </Card>
                )
              })}
          </Grid>
        </MainGrid>
      </VerticalPadding>
    </Container>
  )
}

export default IconsWithText

const Container = styled.section`
  padding: 20px 0 0;
`

const HeadingContainer = styled.div`
  width: 100%;
  max-width: 918px;
  margin: 0 auto;
  text-align: center;
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 30px;
  grid-column-gap: 33px;
  grid-row-gap: 22px;
  @media (min-width: 768px) {
    margin-top: 54px;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 6rem;
  }
`
const Card = styled.div``

const Icon = styled.img`
  width: 100%;
  max-width: 240px;
  display: block;
  margin: 0 auto;
`

const Body = styled.p`
  text-align: center;
  margin-top: 12px;
`
