import React from 'react'
import styled from 'styled-components'
import SectionHeading from '../shared/SectionHeading'
import MainGrid from '../styling/MainGrid'
import theme from '../styling/theme'
import img1 from '../../images/homepage/popin-anim/qualify_team.png'
import InlineLink from '../shared/InlineLink'
import line1 from '../../images/homepage/popin-anim/line-top.svg'
import line2 from '../../images/homepage/popin-anim/line-bottom.svg'
import icon1 from '../../images/homepage/popin-anim/cosmetic.svg'
import icon2 from '../../images/homepage/popin-anim/architecture.svg'
import icon3 from '../../images/homepage/popin-anim/transport.svg'
import icon4 from '../../images/homepage/popin-anim/telecomms.svg'
import icon5 from '../../images/homepage/popin-anim/engineering.svg'
import icon6 from '../../images/homepage/popin-anim/manufacturing.svg'
import icon7 from '../../images/homepage/popin-anim/itsoftware.svg'
import icon8 from '../../images/homepage/popin-anim/retailer.svg'
import mobImg from '../../images/homepage/popin-anim/mobile-circles.svg'

const data = {
  tag: 'QUALIFYING REQUIREMENTS',
  heading: 'Who qualifies for R&D tax credits?',
  body:
    'Any UK-registered limited company, in any industry sector, can apply for R&D tax credits. A successful claim leads to Corporation Tax relief and, for many companies, results in cash remittances from HMRC.',

  iconsTop: [
    {
      icon: icon1,
      alt: 'Cosmetics',
      top: '80%',
      left: '5%',
      delay: 1.4,
    },
    {
      icon: icon2,
      alt: 'Arhitecture',
      top: '50%',
      left: '30%',
      delay: 1,
    },
    {
      icon: icon3,
      alt: 'Transport',
      top: '50%',
      left: '60%',
      delay: 0.7,
    },
    {
      icon: icon4,
      alt: 'Telecomms',
      top: '35%',
      left: '85%',
      delay: 0.5,
    },
  ],
  iconsBottom: [
    {
      icon: icon5,
      alt: 'Engineering',
      top: '80%',
      left: '15%',
      delay: 1.6,
    },
    {
      icon: icon6,
      alt: 'Manufactoring',
      top: '20%',
      left: '45%',
      delay: 0.7,
    },
    {
      icon: icon7,
      alt: 'IT Software',
      top: '45%',
      left: '70%',
      delay: 1.2,
    },
    {
      icon: icon8,
      alt: 'Retailer',
      top: '35%',
      left: '90%',
      delay: 1.8,
    },
  ],
}
const PopInAnimation = ({ isVisible }) => {
  return (
    <Container>
      <MainGrid>
        <TextContent>
          <Card>
            {data.tag && <p className="section-tag">{data.tag}</p>}
            {data.heading && <SectionHeading>{data.heading}</SectionHeading>}
            {data.body && <p className="p-large">{data.body}</p>}
            <InlineLink
              to={
                (process.env.BASE === '/' ? '' : process.env.BASE) +
                '/industry-sector'
              }
              icon
              text="Learn more"
              margintop="20px"
              margintopDesktop="24px"
            />
          </Card>
          <ImgContainer>
            <Img src={img1} alt="Team meeting" />
          </ImgContainer>
        </TextContent>
      </MainGrid>
      <AnimationContainer>
        <LineContainer>
          <LineImg src={line1} alt="Circles on a dotted line" />

          {data.iconsTop &&
            data.iconsTop.map((item, key) => {
              return (
                <Icon
                  src={item.icon}
                  alt={item.alt}
                  key={key}
                  top={item.top}
                  left={item.left}
                  isVisible={isVisible}
                  delay={item.delay}
                />
              )
            })}
        </LineContainer>
        <LineContainer>
          <LineImg src={line2} alt="Circles on a dotted line" />
          {data.iconsBottom &&
            data.iconsBottom.map((item, key) => {
              return (
                <Icon
                  src={item.icon}
                  alt={item.alt}
                  key={key}
                  top={item.top}
                  left={item.left}
                  isVisible={isVisible}
                  delay={item.delay}
                />
              )
            })}
        </LineContainer>
      </AnimationContainer>
      <MobileImg src={mobImg} alt="Circles on a dotted line" />
    </Container>
  )
}

export default PopInAnimation

const Container = styled.section`
  @media (min-width: 768px) {
    padding-top: 5rem;
  }
`

const TextContent = styled.div`
  @media (min-width: 768px) {
    display: flex;
  }
`

const Card = styled.div`
  padding: 62px 30px 96px;
  background: white;
  box-shadow: 0 -29px 54px 1px rgba(100, 100, 100, 0.1);
  width: 100%;
  border-radius: 4px;
  @media (min-width: 768px) {
    max-width: 52.5rem;
    padding: 8rem 6.8rem 11rem;
    margin-right: 5.6rem;
  }

  & .section-tag {
    margin-bottom: 5px;

    @media (min-width: 768px) {
      margin-bottom: 13px;
    }
  }
  & h3 {
    margin-bottom: 10px;

    @media (min-width: 768px) {
      margin-bottom: 6px;
    }
  }
`

const ImgContainer = styled.div`
  width: 100%;
  max-width: 550px;
  margin: 0 auto;
  transform: translateY(-20%);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  @media (min-width: 768px) {
    transform: translateY(0);
    width: 50%;
    align-items: center;
  }
`
const Img = styled.img`
  width: 100%;
`
const AnimationContainer = styled.div`
  display: none;

  @media (min-width: 768px) {
    display: block;
  }
`

const LineContainer = styled.div`
  position: relative;
`

const LineImg = styled.img``

const Icon = styled.img`
  width: 8%;
  position: absolute;
  top: ${props => props.top};
  left: ${props => props.left};
  transform: scale(0) translateY(-50%);
  transform-origin: top center;
  transition: transform 0.2s ease;
  animation-duration: 0.9s;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  animation-delay: ${props => props.delay};
  animation-fill-mode: forwards;
  backface-visibility: visible;
  will-change: transform;
  animation: ${props =>
    props.isVisible
      ? `popincircle 0.9s cubic-bezier(0.16, 1, 0.3, 1) ${props.delay}s forwards`
      : 'none'};

  @keyframes popincircle {
    from {
      transform: scale(0) translateY(-50%);
    }
    to {
      transform: scale(1) translateY(-50%);
    }
  }
`
const MobileImg = styled.img`
  display: block;
  transform: translateY(-10%);
  @media (min-width: 768px) {
    display: none;
  }
`
