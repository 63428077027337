import React, { useState } from 'react'
import styled from 'styled-components'
import LargeGrid from './styling/LargeGrid'
import Button from './shared/Button/Button'
import theme from './styling/theme'
import RequestCallBackModal from './shared/Modal/RequestCallBackModal'

const data = {
  heading: 'Any questions? Talk to our specialists today',
  bodyHeading: 'Request a call back',
  body:
    'To request a call back at any time, one of our team will respond to you within 24 hours',
  btnUrl1: '/call-back-form',
  btnTitle1: 'Request a call back',

  openingTime: '09:00',
  closingTime: '17:00',
  isOenOnWeekends: false,
}

//What are the states.
// currently open

// currently close on weekday
// currently close on weekend

interface getIsOpenI {
  open: string
  close: string
}

function getIsOpen(open, close) {
  // Converting the time strings into UNIX timestamps we can can caluclate if we are open for business or not.
  const openArray = open.split(':')
  const closeArray = close.split(':')

  const closeDateTime = new Date()
  closeDateTime.setHours(closeArray[0])
  closeDateTime.setMinutes(closeArray[1])

  const openDateTime = new Date()
  openDateTime.setHours(openArray[0])
  openDateTime.setMinutes(openArray[1])

  const currentTime = new Date()

  // console.log(openDateTime)
  // console.log(closeDateTime)
  // console.log(currentTime)

  const openingTimeInt = parseInt((openDateTime.getTime() / 1000).toFixed(0))
  const closingTimeInt = parseInt((closeDateTime.getTime() / 1000).toFixed(0))
  const currentTimeInt = parseInt((currentTime.getTime() / 1000).toFixed(0))

  if (openingTimeInt < currentTimeInt && currentTimeInt < closingTimeInt) {
    // console.log('WE ARE OPEN!')
    // unless it is a weekend?
    return true
  } else {
    // console.log('WE ARE CLOSED!')
    return false
  }
}

const CallBackBanner = () => {
  const [showCallBackModal, toggleCallBackModal] = useState(false)
  const isOpen = getIsOpen(data.openingTime, data.closingTime)
  return (
    <LargeGrid>
      <RequestCallBackModal
        showModal={showCallBackModal}
        toggleModal={() => toggleCallBackModal(false)}
        type={'Request a call back'}
        body={'Inactive account'}
      />
      <Background>
        <Container>
          <Col>
            <h4>{data.heading}</h4>
            <Line />
          </Col>

          <Col>
            {data.bodyHeading && <CallBack>{data.bodyHeading}</CallBack>}

            <p>{data.body}</p>
            <ButtonContainer>
              <Button onClick={() => toggleCallBackModal(true)} secondary>
                {data.btnTitle1}
              </Button>
            </ButtonContainer>
          </Col>
          <Col>
            {data.bodyHeading && <CallBack>Call us today</CallBack>}

            <p>Alternatively you can call us and chat to an R&D specialist.</p>
            <TelNo href="tel:033 33 444 026">033 33 444 026</TelNo>

            <ButtonContainerTelNo>
              <Button href="tel:033 33 444 026" secondary>
                033 33 444 026
              </Button>
            </ButtonContainerTelNo>
            <PillContainer>
              <Pill>{isOpen ? 'OPEN NOW' : 'CLOSED'}</Pill>
              <PillText>CLOSES AT {data.closingTime}</PillText>
            </PillContainer>
          </Col>
        </Container>
      </Background>
    </LargeGrid>
  )
}

export default CallBackBanner

const Background = styled.div`
  background: ${theme.brand.blue};
  border-radius: 4px;
  transition: background 1s ease;
  @media (min-width: 1024px) {
    background: linear-gradient(
      to right,
      #006822 33%,
      #0b8e36 33%,
      #0b8e36 100%
    );
  }
`

const Container = styled.section`
  display: flex;
  flex-direction: column;

  width: 100%;
  max-width: 1140px;
  margin: 0 auto;
  @media (min-width: 768px) {
    border-radius: 5px;
  }

  @media screen and (min-width: 1024px) {
    flex-direction: row;
    justify-content: space-between;
    width: 90%;
  }

  @media screen and (min-width: 1140px) {
    padding: 0;
  }
`

const Col = styled.div`
  padding: 48px 37px 42px;
  & a {
    font-weight: bold;
  }
  & h4,
  & p {
    color: ${theme.text.white};
  }

  & h4 {
    margin-bottom: 20px;
    font-weight: 700;
    @media (min-width: 1024px) {
      max-width: 230px;
    }
  }

  &:first-of-type {
    background: ${theme.brand.hover};
    border-radius: 4px 4px 0 0;
    @media (min-width: 768px) {
      border-radius: none;
    }
  }

  &:last-of-type {
    padding: 8px 37px 42px;
  }
  @media (min-width: 1024px) {
    width: 28%;
    padding: 7rem 0 10rem;
    &:last-of-type {
      padding: 7rem 0 10rem;
    }
  }
`

const Line = styled.div`
  background: ${theme.text.white};
  height: 3px;
  width: 100%;
  @media (min-width: 1024px) {
    max-width: 260px;
  }
`
const ButtonContainer = styled.div`
  width: 100%;
  margin-top: 12px;
  max-width: 450px;
  margin: 12px 0 0;
  display: flex;
  @media (min-width: 1024px) {
    max-width: 252px;
    margin: 21px 0 0;
    justify-content: flex-start;
  }
`

const ButtonContainerTelNo = styled(ButtonContainer)`
  @media (min-width: 1024px) {
    display: none;
  }
`

const CallBack = styled.h6`
  color: white;
  font-weight: 700;
  margin-bottom: 3px;

  @media (min-width: 1024px) {
    margin-bottom: 7px;
  }
`

const TelNo = styled.a`
  &,
  &:link,
  &:visited {
    text-decoration: none;
    margin-top: 5px;
    color: white;
    font-size: 26px;
    font-weight: 700;
    letter-spacing: -0.43px;
    line-height: 30px;
    display: none;
  }
  &:hover,
  &:active {
    text-decoration: underline;
  }
  @media (min-width: 1024px) {
    &,
    &:link,
    &:visited {
      display: block;
      margin-top: 20px;
    }
  }
`
const PillContainer = styled.div`
  margin-top: 11px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (min-width: 1024px) {
    margin-top: 14px;
    justify-content: flex-start;
  }
`

const Pill = styled.div`
  color: #ffffff;
  font-size: 13px;
  font-family: Roboto;
  font-weight: 700;
  line-height: 20px;
  text-align: center;
  height: 27px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 18px;
  background: ${theme.text.main};
  border-radius: 17px;
  white-space: nowrap;
`

const PillText = styled.p`
  color: ${theme.text.white};
  margin-left: 7px;
`
